import React from "react";

import {
    AdminFiltering,
    DefaultLayout,
    Layout,
    withPrivateRoute,
} from "@containers";

const AdminFilteringPage = props => (
    <Layout {...props}>
        <DefaultLayout title="Beheer filters">
            <AdminFiltering id="beheer-filters" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdminFilteringPage);
